import Layout from '@/apps/core/components/Layout'
import sections from '../../../../dido/views/defaultNavigationDrawerSections'
import AgreementsTable from '../../../components/tables/AgreementsTable/AgreementsTable'

export default {
  name: 'AgreementList',
  components: { AgreementsTable, Layout },
  data () {
    return {
      sections: sections,
      title: this.$t('agreements.listSectionName')
    }
  }
}
