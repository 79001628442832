import { mapState } from 'vuex'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { RESPONSE_LEVEL } from '@/variables'

export default {
  name: 'AgreementList',
  components: {
    CrudTable
  },
  props: {
    'title': {
      type: String,
      default: null
    }
  },
  data () {
    return {
      storeModule: 'agreements'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),

    linkAttributes () {
      const linkAttributes = {}
      let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''
      linkAttributes['path'] = `item.${value}`
      linkAttributes['name'] = value
      return linkAttributes
    }
  },
  methods: {
    async onCreateAgreement () {
      await this.$store.dispatch(`${this.storeModule}/createAgreement`)
        .then(response => {
          if (response.level === RESPONSE_LEVEL.SUCCESS) {
            this.$router.push({
              name: 'AgreementDetail',
              params: { id: response.id }
            })
          }
        })
    }
  }
}
